import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 500;
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.text};
    }
  }
`

const Footer = () => (
  <Wrapper>
    <List>
    <Item>
    <div class="suodatin2">
    <center><h4>K18 - Säännöt & ehdot pätevät</h4></center>
<p>Pelaamisen tulee olla hauskaa viihdettä! Älä koskaan pelaa yli varojesi tai yritä ratkoa taloudellisia ongelmia uhkapelaamisella.</p>
<p>Aiheuttaako pelaaminen sinulle ongelmia?</p>
<p>Ammattiapua on tarjolla:</p>
<p><a href="https://pelirajaton.fi/" TARGET="_blank">www.pelirajaton.fi</a></p>
<p><a href="https://www.peluuri.fi/fi" TARGET="_blank">www.peluuri.fi</a></p>
<p>Auttava puhelin: <b>0800 100 101</b></p></div>
      </Item>
      <Item>
      </Item>
      <Item>
      </Item>
      <Item>
      <center><img
            width='240'
            height='152'
            className='lazyload'
            data-src="/images/vastuullinen.webp"
            alt="Vastuullinen pelaaminen"
            title="Vastuullinen pelaaminen"
          /></center>
      <center><p>contact@uusikasino.com</p></center>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
